import {
  Box,
  Flex,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { BsArrowRightCircle } from 'react-icons/bs';
import PopUpCard from './PopUpCard';

/**
 *
 * @param {Object} postData
 * @returns
 */
const TestimonialCard = ({ sectionContent, postData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      flexDirection="column"
      maxW={{ base: '293.513px', md: '355px' }}
      textAlign="center"
    >
      <Box
        display="flex"
        flexDirection={{ md: 'row' }}
        marginTop="25px"
        className="Individual container"
      >
        <Box width={{ base: 'auto' }} pos="relative">
          {postData?.tagType && (
            <Box
              pos="absolute"
              bottom="10px"
              display={{ base: 'none', md: 'block' }}
              right={0}
              width="auto"
              padding="5px"
              minW={171}
              height={33}
              background="#D8A24C"
            >
              <Text
                color="#fff"
                textAlign="center"
                fontFamily="FedraSansCondensedMedium, sans-serif"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="27px"
                textTransform="uppercase"
              >
                {postData?.tagType}
              </Text>
            </Box>
          )}
          <Image
            alt={postData.title}
            src={postData.image?.url}
            w={{ base: '293.513px', md: '305px', lg: '355px' }}
            h={{ base: '370.646px', md: '340px', lg: '447.16px' }}
            objectFit="cover"
          />
        </Box>
      </Box>
      <Box>
        <Text
          fontFamily="'FedraSansStd-medium', sans-serif"
          fontStyle="normal"
          letterSpacing="-0.098px"
          fontWeight={500}
          color="#fff"
          fontSize={{ base: '20px', md: '24px' }}
          lineHeight={{ base: '22.495px', md: '27.139px' }}
          mt={{ base: '30px', md: '30px' }}
        >
          {postData?.title}
        </Text>
        <Text
          color="#D4D4FA"
          lineHeight={{ base: '20px', md: '24px' }}
          fontSize={{ base: '14px', md: '16px' }}
          mt={{ base: '1px', md: '5px' }}
          fontWeight={400}
          fontStyle="normal"
        >
          {postData?.designation}
        </Text>
        <Text
          onClick={onOpen}
          cursor="pointer"
          color="#F27123"
          fontFamily="FedraSansStd-book"
          fontSize={{ base: '16px', md: '18px' }}
          fontStyle="normal"
          fontWeight="450"
          lineHeight={{ base: '16.871px', md: '20.354px' }}
          letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
          textDecoration="underline"
          display="flex"
          justifyContent="center"
          mt={{ base: '16px', md: '26px' }}
          _hover={{ color: '#BA5010' }}
        >
          <Text as="span" mr="5px" textTransform="uppercase">
            {sectionContent?.readMorePlaceholder}
          </Text>
          <BsArrowRightCircle style={{ marginTop: '3px' }} />
        </Text>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'xs', md: 'xs' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          rounded="none"
          maxW={1150}
          height={{ base: '90%', md: '571px' }}
          p={{ base: '50px 0 0', md: '40px 26px 31px 26px' }}
        >
          <ModalCloseButton />
          <ModalBody pt={{ base: '0' }} overflow="auto">
            <PopUpCard postData={postData} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TestimonialCard;
