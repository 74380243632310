import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import SectionTitle from '../components/Text/SectionTitle';
import { useMediaQuery } from 'react-responsive';

const InterestedForm = ({ sectionContent, secId }) => {
  const desktopId = 'interestedformDesktop';
  const mobileId = 'interestedform';
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const currentId = isMobile ? mobileId : desktopId;
  return (
    <>
      {sectionContent[0]?.titleDecoration !== 'tab-hide' && (
        <Box
          maxW={{
            base: '100%',
            sm: '540px',
            md: '720px',
            lg: '960px',
            xl: '1170px',
            '2xl': '1330px',
          }}
          px={{ base: '16px', sm: 0 }}
          mx="auto"
          w="100%"
          py={{ base: '40px', md: '40px', xl: '60px' }}
          id="interested-form"
        >
          <Flex flexDirection={{ base: 'column', lg: 'row' }}>
            <Box
              id={currentId}
              w={{ base: '100%', xl: '497px' }}
              px={{ base: '4px', md: '5px' }}
              mt={{ base: '0', md: '30px' }}
              mb="20px"
            >
              <SectionTitle
                titleObj={sectionContent[0]}
                subtxtColor={'#FFFFFF'}
              />
            </Box>
            <Box
              maxW={{ lg: '657px' }}
              p={{ base: '0', md: '5px' }}
              pl={{ base: '4px', md: '4px' }}
            >
              <Box
                id="my-react-component"
                className="inline-form"
                data-props={`{"isSeparateLinkShow":false, "eventId": ${sectionContent[1]?.text}, "colorScheme":"primary"}`}
              ></Box>
            </Box>
          </Flex>
          <style jsx global>
            {`
              .css-11geh0d, .css-1s5stzm {
                  font-family: FedraSansStd-medium,sans-serif !important;
              }
              .css-152bfrp, .css-jx9d4g, .css-1uhw3wc, .css-4tl7lb, .css-gzvvcd, .css-1id6921 {
                  font-family: FedraSansStd-Book,sans-serif !important;
              }
              .css-n75q0x,
              .css-10w333y {
                width: 657px;
                margin: 0 auto !important;
                padding: 0px;
              }
              .css-10w333y {
                padding: 50px 0 50px 0;
                width: 657px !important;
              }
              .inline-form h2 {
                color: #ffffff;
                font-size: 38px;
                text-align: left;
              }
              .inline-form .css-kwu9rq {
                grid-template-columns: repeat(1, 1fr);
                padding: 20px 40px;
              }
              .css-10w333y hr {
                border-bottom-width: 0px;
                display: none;
              }
              .css-10w333y p {
                font-family: FedraSansStd-book, sans-serif !important;
                font-size: 16px;
                line-height: 22px;
                color: #433f3c;
                text-align: center;
                padding: 10px 0;
              }
              .css-10w333y h3 {
                font-size: 20px;
                color: #00a171;
              }
              .css-10w333y svg {
                width: 81px;
                height: 81px;
              }
              .css-ok4vqq {
                height: auto !important;
                padding: 60px 0;
              }
              @media (max-width: 767px) {
                .css-n75q0x,
                .css-10w333y {
                  width: 100%;
                }
                .inline-form .css-kwu9rq {
                  padding: 0px;
                }
                .css-10w333y h3 {
                  font-size: 18px;
                }
                .css-10w333y svg {
                  width: 40px;
                  height: 40px;
                }
                .css-1rss8o5 {
                  padding-left: 14px;
                  padding-right: 14px;
                }
                .css-ok4vqq {
                  padding: 20px 0 60px 0;
                }
              }
            `}
          </style>
        </Box>
      )}
    </>
  );
};

export default InterestedForm;
