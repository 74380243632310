import { Box, Flex } from '@chakra-ui/react';
//import InterestedForm from '@ila/Section/InterestedForm';

/**
 *
 */
const StickyBar = ({ sectionContent, secId = '' }) => {
  let progname = '';
  if (typeof window !== 'undefined') {
    let url = window.location.href;
    if (url.includes('/human-is-not-a-resource')) {
      progname = 'HINAR';
    } else if (url.includes('/family-enterprise-excellence')) {
      progname =
        'Family Enterprise Excellence - Mapping the Future of Your Business';
    } else if (url.includes('/insight')) {
      progname = 'INSIGHT - The DNA of Success, 2024';
    } else if (url.includes('/brand-insight')) {
      progname = 'Brand Insight - Decoding Branding';
    }
  }
  const interestedClick = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Interested_Users',
        program_name: progname,
        Button_Location: '<Sticky_CTA>',
      });
      //if(progname == 'INSIGHT'){
      //smooth scroll to interested form section
      const element = document.getElementById('interested-form');
      element?.scrollIntoView({ behavior: 'smooth' });
      // <InterestedForm sectionContent={sectionContent} secId={secId} />

      //}
    }
  };

  return (
    <>
      {sectionContent[0]?.linkUrl !== '' && (
        <Box
          maxW={{
            base: '100%',
            md: '100%',
            lg: '100%',
            xl: 1330,
            '2xl': '1330px',
          }}
          mx="auto"
          w="100%"
          position="relative"
        >
          {sectionContent[0]?.linkUrl && (
            <Flex
              justify="center"
              position="fixed"
              zIndex="99999"
              width="full"
              bg="rgb(0 0 29 / 83%)"
              left="0"
              py="20px"
              top="calc(100dvh - 80px)"
            //onClick={() => interestedClick()}
            >
              <Box
                outline="0"
                onClick={() => interestedClick()}
                cursor={'pointer'}
                //href={sectionContent[0]?.linkUrl}
                px="20px"
                py="10px"
                color="#fff"
                fontFamily="var(--FedraSansStd-medium), sans-serif"
                background="#F27123"
                borderRadius="0px"
                border="none"
                _hover={{ background: '#BA5010' }}
                _focus={{ background: '#BA5010' }}
                _active={{ background: '#BA5010', border: 0 }}
              >
                {sectionContent[0]?.buttonText}{' '}
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </>
  );
};

export default StickyBar;
